export default {
  cancel: 'Cancelar',
  select: 'Selecionar',
  hotels: 'Hotéis',
  experiences: 'Experiências',
  flights: 'Voos',
  destination: 'Destino',
  origin: 'Origem',
  select_dates: 'Selecione suas datas de chegada e saída',
  arrive_leave_dates: 'Datas de Chegada e Saída',
  all_inclusive: 'Tudo incluído',
  breakfast_inclusive: 'Café da manhã incluído',
  seaside: 'À beira-mar',
  pool: 'Piscina',
  ac: 'Ar-condicionado',
  bar: 'Bar',
  restaurant: 'Restaurante',
  exceptional: 'Excepcional',
  opinions: 'opiniões',
  guest: 'Hóspede | Hóspedes',
  room: 'Quarto | Quartos',
  adults: 'Adultos',
  minors: 'Menor | Menores',
  minor_age: 'Menor {count}',
  age: 'Idade',
  incomplete_ages: 'Idades dos menores não preenchidas',
  years: 'Ano | Anos',
  language: 'Idioma',
  currency: 'Moeda',
  search: 'Buscar',
  price: 'Preço',
  from: 'De',
  to: 'Até',
  filters: 'Filtros',
  order_by: 'Ordenar por',
  name: 'Nome',
  search_experiences: 'Buscar Experiências',
  categories: 'Categorias',
  empty_experiences: 'Parece que estamos enfrentando mau tempo e não encontramos experiências disponíveis para sua busca.',
  empty_hotels: 'Parece que estamos enfrentando mau tempo e não encontramos hotéis disponíveis para sua busca.',
  empty_transfers: 'Parece que estamos enfrentando mau tempo e não encontramos transferências disponíveis para sua busca.',
  continue_payment: 'Continuar com o pagamento',
  order_error: 'Você deve preencher todos os campos do formulário para fazer a reserva.',
  reservation_holder: 'Titular da reserva',
  last_name: 'Sobrenome',
  country: 'País',
  contact_info: 'Informações de contato',
  agency: 'Agência',
  agent: 'Agente',
  email: 'E-mail',
  phone: 'Telefone',
  i_agree: 'Eu concordo com',
  terms_and_conditions: 'Termos e Condições',
  commission: 'Comissão',
  reservation_summary: 'Resumo da reserva',
  date: 'Data',
  restrictions: 'Restrições',
  included: 'Incluído',
  recommendations: 'Recomendações',
  contact: 'Contato',
  book: 'Finalizar',
  total: 'Total',
  confirm_reservation: 'Confirmar reserva',
  congrats: 'Parabéns! A reserva com o número <b>{order}</b> foi gerada com sucesso. Confirme sua reserva gerando o pagamento. Se tiver dúvidas, entre em contato conosco.',
  no_destination: 'Selecione um destino',
  no_origin: 'Selecione uma origem',
  promotions: 'Promoções',
  promotions_1: 'Descubra a escapada perfeita com nossas promoções exclusivas',
  promotions_2: 'Reserve estadias, experiências e transferências com as melhores comissões',
  survey: 'Sua opinião é importante para nós. Você se importaria em compartilhar seu feedback na nossa rápida pesquisa de qualidade?',
  click_here: 'Clique aqui',
  survey_url: 'https://survey.hsforms.com/1h6a0lUNgRFWt8S5SSAuq2g3jdul',
  rewards: 'Recompensas',
  asset_program: 'Acesse o portal de recompensas ASSET e confira os benefícios obtidos com as reservas geradas. Controle e resgate suas conquistas que o programa oferece.',
  asset_button: 'Ir para o site de recompensas',
  open_details: 'Abrir detalhes',
  search_hotels: 'Buscar Hotéis',
  search_transfers: 'Buscar Transferências',
  available_rooms: 'Quartos Disponíveis',
  total_from: 'Total a partir de',
  choose_plan: 'Escolher Plano',
  room_unavailable: 'Quarto Indisponível',
  retry_payment: 'Tentar Pagamento Novamente',
  bad_weather: 'Ops, parece que estamos <br> enfrentando mau tempo.',
  payment_no_success: 'Seu processo de pagamento não foi bem-sucedido',
  try_payment_again: 'Por favor, tente novamente ou use outro método.',
  show_commissions: 'Mostrar comissões',
  logout: 'Sair',
  thanks: 'Obrigado pela sua compra',
  enjoy: 'Aproveite sua visita',
  back_home: 'Voltar para a página inicial',
  your_reservation: 'O número da sua reserva é: <b>{order}</b>',
  invalid_credentials: 'Suas credenciais não são válidas.',
  login: 'Entrar',
  privacy_policy: 'POLÍTICA DE PRIVACIDADE',
  hotels_in: 'Hotéis em',
  all_category: 'Todas as experiências',
  see_all: 'Ver Tudo',
  credit_card: 'Cartão de Crédito',
  wire_transfer: 'Transferência Bancária',
  complete_reservation: 'Concluir reserva',
  discount: 'Desconto',
  select_discount: 'Selecionar Desconto',
  remove_discount: 'Remover desconto',
  remove: 'Remover',
  tax_included: 'Impostos incluídos',
  select_agency: 'Selecionar uma agência',
  book_with: 'Reservar com',
  down_payment: 'Pagamento Inicial',
  non_refundable: 'Não reembolsável',
  refundable_rate: 'Tarifa Flexível',
  password: 'Senha',
  confirm_password: 'Confirmar senha',
  invalid_url: 'URL inválida ou expirada',
  setup_your_password: 'Olá <b>{name}</b>, vamos configurar sua nova senha.',
  password_must: 'A senha deve conter:',
  password_size: 'Pelo menos 8 caracteres.',
  password_upper: 'Letras maiúsculas e minúsculas.',
  password_special: 'Pelo menos 1 caractere especial.',
  password_digit: 'Pelo menos 1 dígito.',
  password_match: 'A confirmação da senha deve coincidir.',
  set_password: 'Definir senha',
  go_to_login: 'Ir para o login',
  password_congrats: 'Parabéns, <b>{name}</b>. Sua nova senha foi configurada com sucesso. Clique no botão "Ir para o login" para acessar sua conta.',
  transfers: 'Transferências',
  transfer: 'Transferência',
  book_from_airport: 'Reserve sua transferência do aeroporto.',
  search_transfer: 'Encontrar sua transferência',
  departure_date: 'Data de Saída',
  return_date: 'Data de Retorno',
  one_way: 'Somente Ida',
  round: 'Ida e Volta',
  transport_type: 'Tipo de Transporte',
  page_not_found: 'Página Não Encontrada',
  server_error: 'Erro no servidor',
  try_again: 'Tente Novamente',
  manage_agency: 'Gerenciar agência',
  my_agency: 'Minha agência',
  reservations: 'Reservas',
  commissions: 'Comissões',
  agents: 'Agentes',
  paid: 'Pago',
  pending: 'Pendente',
  beneficiary: 'Beneficiário da reserva',
  check_in: 'Check-in',
  check_out: 'Check-out',
  nights: 'Noite | Noites',
  plan: 'Plano',
  payment: 'Pagamento',
  reservation_details: 'Detalhes da Reserva',
  search_by_name_or_email: 'Pesquisar por número do pedido',
  order: 'Pedido',
  summary: 'Resumo',
  total_to_pay: 'Total a pagar',
  pending_payment: 'Pagamento pendente',
  confirmation_code: 'Confirmação',
  operation_date: 'Data da operação',
  description: 'Descrição',
  reservation_items: 'Itens da reserva',
  item_number: 'Número do item',
  reserved: 'Reservado',
  cancelled: 'Cancelado',
  paid_status: 'Pago',
  operated: 'Operado',
  requested: 'Solicitado',
  no_show: 'Não compareceu',
  audited: 'Auditado',
  modified: 'Modificado',
  annulled: 'Anulado',
  blocked: 'Bloqueado',
  booked: 'Reservado',
  my_reservations: 'Reservas',
  advanced_search: 'Busca Avançada',
  reservation_date: 'Data da reserva',
  product: 'Produto',
  reservation_status: 'Status da reserva',
  confirmation_status: 'Status da confirmação',
  payment_status: 'Status do pagamento',
  payment_method: 'Método de pagamento',
  not_paid: 'Não pago',
  confirmed: 'Confirmado',
  clear_filters: 'Limpar Filtros',
  apply_filters: 'Buscar',
  services: 'Serviços',
  order_number: 'Número do pedido',
  confirmed_services: 'Serviços confirmados',
  booking: 'Reserva',
  amount: 'Valor',
  balance: 'Saldo',
  item: 'Item|Itens',
  of: 'de',
  created_at: 'Criado em',
  by: 'por',
  arrive: 'Chegada',
  leave: 'Saída',
  remember_me: 'Lembrar de mim',
  passengers: 'Passageiro | Passageiros',
  estimate_duration: 'Duração estimada por viagem: {duration}',
  max_passengers: 'Até {count} passageiros',
  max_suitcases: 'Até {count} malas de tamanho médio',
  door_to_door: 'Serviço porta a porta',
  transfer_cancel_policy: 'Cancelamentos ou alterações 48 horas antes sem custo.',
  airline: 'Companhia aérea',
  flight_number: 'Número do voo',
  arrival_time: 'Horário de chegada',
  departure_time: 'Horário de partida',
  notes: 'Observações',
  flight_info: 'Informações do voo',
  arriving: 'Chegando',
  arrival: 'Chegada',
  departure: 'Partida',
  pick_up: 'Pegar',
  international_flight: 'Voo Internacional',
  first_trip: 'Primeira viagem',
  second_trip: 'Segunda viagem',
  commission_pending: 'Comissão pendente',
  commission_discount: 'Desconto na comissão',
  resend_invite: 'Reenviar convite',
  resend_error: 'O convite não pôde ser enviado, tente novamente.',
  resend_success: 'O convite foi enviado com sucesso.',
  email_error: 'Endereço de e-mail inválido.',
  phone_error: 'Número de telefone ou celular inválido. Máximo 10 dígitos.',
  more_images: 'Mais imagens',
  book_hotel: 'Reservar Hotel',
  see_details: 'Ver detalhes',
  stay: 'Estadia',
  travel_complements: 'Complementos de viagem',
  find_more_experiences: 'Encontre mais experiências por categoria',
  category: 'Categoria',
  average_price: 'Preço médio por pessoa para',
  average_per_person: 'Média por pessoa',
  select_date: 'Selecionar data',
  add: 'Adicionar',
  quote: 'Cotação',
  continue_reservation: 'Continuar reserva',
  check_reservation: 'Revisar Reserva',
  subtotal: 'Subtotal',
  select_origin: 'Selecione o aeroporto de origem.',
  add_transfer: 'Adicionar Transfer',
  active: 'Ativo',
  inactive: 'Inativo',
  status: 'Status',
  add_agent: 'Adicionar Agente',
  mobile: 'Celular',
  save: 'Salvar',
  update_agent: 'Editar Agente',
  agent_error: 'O agente não pôde ser salvo, verifique as informações e tente novamente.',
  cross_sell_text: 'Torne sua estadia inesquecível! Oferecemos complementos exclusivos para que você aproveite ao máximo sua viagem. Escolha entre transfers confortáveis e atividades emocionantes para dar um toque especial à sua experiência. <b>Esses complementos são opcionais</b> e projetados para proporcionar maior conforto e diversão durante sua estadia.',
  forgot_password: 'Esqueceu sua senha?',
  send: 'Enviar',
  forgot_instructions_1: 'Digite seu endereço de e-mail e enviaremos um link para redefinir sua senha.',
  forgot_instructions_2: 'Se você não receber o e-mail, verifique sua pasta de spam ou tente novamente.',
  password_email_sent: 'Um e-mail foi enviado para sua caixa de entrada com as instruções para redefinir sua senha.',
  my_commissions: 'Minhas Comissões',
  pending_payments: 'Pagamentos Pendentes',
  received_payments: 'Pagamentos Recebidos',
  pending_commissions: 'Comissões Pendentes',
  paid_commissions: 'Comissões Pagas',
  payment_date: 'Data do Pagamento',
  total_pending_commissions: 'Total de Comissões Pendentes',
  estimated_payment_date: 'Data Estimada do Pagamento',
  payment_amount: 'Valor do Pagamento',
  reservations_number: 'Quantidade de Reservas',
  details: 'Detalhes',
  included_reservations: 'Reservas Incluídas',
  hotel: 'Hotel',
  experience: 'Experiência',
  percentage: 'Porcentagem',
  commission_amount: 'Valor da Comissão',
  total_commissions: 'Total das Comissões',
  total_paid_commissions: 'Total das Comissões Pagas',
  payment_reference: 'Referência',
  the_reservation_is_in_euros: 'A reserva é exibida em euros (€); no entanto, a cobrança será feita em dólares americanos (USD) com a taxa de câmbio vigente no momento da transação.',
  commission_discounts_not_included: 'Descontos na comissão não incluídos.',
  price_to_pay: 'Preço a pagar',
  resend_email: 'Para sua conveniência, podemos enviar seu itinerário de viagem por e-mail. Basta inserir seu endereço de e-mail no campo abaixo e clicar em «Enviar».',
  email_sended: 'O itinerário de viagem foi enviado com sucesso.',
  email_send_failed: 'Parece que o itinerário de viagem não foi enviado. Por favor, tente novamente.'
}
