export default {
  cancel: 'Cancelar',
  select: 'Seleccionar',
  hotels: 'Hoteles',
  experiences: 'Experiencias',
  flights: 'Vuelos',
  destination: 'Destino',
  origin: 'Origen',
  select_dates: 'Selecciona las fechas de llegada y salida',
  arrive_leave_dates: 'Fechas Entrada y Salida',
  all_inclusive: 'Todo incluido',
  breakfast_inclusive: 'Desayuno incluido',
  seaside: 'Vista al mar',
  pool: 'Piscina',
  ac: 'AC',
  bar: 'Bar',
  restaurant: 'Restaurante',
  exceptional: 'Excepcional',
  opinions: 'opiniones',
  guest: 'Huésped | Huéspedes',
  room: 'Habitación | Habitaciones',
  adults: 'Adultos',
  minors: 'Menor | Menores',
  minor_age: 'Menor {count}',
  age: 'Edad',
  incomplete_ages: 'Edades de menores incompletas',
  years: 'año | años',
  language: 'Idioma',
  currency: 'Moneda',
  search: 'Buscar',
  price: 'Precio',
  from: 'Desde',
  to: 'Hasta',
  filters: 'Filtros',
  order_by: 'Ordenar por',
  name: 'Nombre',
  search_experiences: 'Buscar Experiencias',
  categories: 'Categorías',
  empty_experiences: 'Parece que experimentamos mal clima y no hemos encontrado experiencias disponibles para tu búsqueda.',
  empty_hotels: 'Parece que experimentamos mal clima y no hemos encontrado hoteles disponibles para tu búsqueda.',
  empty_transfers: 'Parece que experimentamos mal clima y no hemos encontrado traslados disponibles para tu búsqueda.',
  continue_payment: 'Continuar con el pago',
  order_error: 'Debe llenar todos los campos del formulario para hacer la reservación.',
  reservation_holder: 'Titular de la reservación',
  last_name: 'Apellido',
  country: 'País',
  contact_info: 'Información de contacto',
  agency: 'Agencia',
  agent: 'Agente',
  email: 'Correo',
  phone: 'Teléfono',
  i_agree: 'Acepto',
  terms_and_conditions: 'términos y condiciones',
  commission: 'Comisión',
  reservation_summary: 'Resumen de reservación',
  date: 'Fecha',
  restrictions: 'Restricciones',
  included: 'Incluido',
  recommendations: 'Recomendaciones',
  contact: 'Contacto',
  book: 'Reservar',
  total: 'Total',
  confirm_reservation: 'Confirmar reservación',
  congrats: '¡Felicidades! se ha generado la reservación con número <b>{order}</b> satisfactoriamente. Confirma tu reservación generando tu pago, en caso de tener dudas contáctanos.',
  no_destination: 'Seleccione un destino',
  no_origin: 'Seleccione un origen',
  promotions: 'Promociones',
  promotions_1: 'Descubre el escape perfecto con nuestras promociones exclusivas',
  promotions_2: 'Reserva estancias, experiencias y traslados con las mejores comisiones',
  survey: 'Tu opinión es importante para nosotros. ¿Te importaría contestar una breve encuesta sobre tu experiencia en nuestro sitio web?',
  click_here: 'Da clic aquí',
  survey_url: 'https://survey.hsforms.com/1xmQkpVtFTkGkokAJVv0n5g3jdul',
  rewards: 'Recompensas',
  asset_program: 'Entra al portar de recompensas ASSET y consulta tus beneficios obtenidos por las reservaciones generadas. Lleva el control y redime tus logros que te ofrece el programa.',
  asset_button: 'Ir al sito de recompensas',
  open_details: 'Ver detalles',
  search_hotels: 'Buscar Hoteles',
  search_transfers: 'Buscar Traslados',
  available_rooms: 'Habitaciones Disponibles',
  total_from: 'Total desde',
  choose_plan: 'Elegir Plan',
  room_unavailable: 'Habitación No Disponible',
  retry_payment: 'Reintentar Pago',
  bad_weather: 'Oops, Parece que <br>estamos experimentando mal clima.',
  payment_no_success: 'Tu proceso de pago no ha sido exitoso',
  try_payment_again: 'Inténtalo nuevamente o intenta con otro método.',
  show_commissions: 'Mostrar comisiones',
  logout: 'Cerrar sesión',
  thanks: 'Gracias por tu compra',
  enjoy: 'Disfruta tu estancia',
  back_home: 'Regresar al inicio',
  your_reservation: 'Tu número de reservación es: <b>{order}</b>',
  invalid_credentials: 'Tus credenciales no son válidas',
  login: 'Iniciar sesión',
  privacy_policy: 'POLÍTICA DE PRIVACIDAD',
  hotels_in: 'Hoteles en',
  all_category: 'Todas las experiencias',
  see_all: 'Ver todas',
  credit_card: 'Tarjeta de crédito',
  wire_transfer: 'Transferencia bancaria',
  complete_reservation: 'Completar reservación',
  discount: 'Descuento',
  select_discount: 'Seleccionar descuento',
  remove_discount: 'Remover descuento',
  remove: 'Remover',
  tax_included: 'Impuestos incluidos',
  select_agency: 'Selecciona una agencia',
  book_with: 'Reserva con ',
  down_payment: 'Pago Inicial',
  non_refundable: 'No Reembolsable',
  refundable_rate: 'Tarifa Flexible',
  password: 'Contraseña',
  confirm_password: 'Confirmar contraseña',
  invalid_url: 'Invalid or expired url',
  setup_your_password: 'Hola <b>{name}</b>, vamos a establecer tu contraseña.',
  password_must: 'La contraseña debe contener:',
  password_size: 'Al menos 8 caracteres.',
  password_upper: 'Mayúsculas y minúsculas.',
  password_special: 'Al menos un caracter especial.',
  password_digit: 'Al menos un dígito.',
  password_match: 'La confirmación debe ser igual.',
  set_password: 'Establecer contraseña',
  go_to_login: 'Iniciar sesión',
  password_congrats: 'Genial, <b>{name}</b>. Tu nueva contraseña se ha establecido. Presiona "Iniciar sesión" para acceder a tu cuenta.',
  transfers: 'Traslados',
  transfer: 'Traslado',
  book_from_airport: 'Reserva tus traslados desde el aeropuerto.',
  search_transfer: 'Busca tu traslado',
  departure_date: 'Fecha Salida',
  return_date: 'Fecha de Regreso',
  one_way: 'Sencillo',
  round: 'Redondo',
  transport_type: 'Tipo de transporte',
  page_not_found: 'Página No Encontrada',
  server_error: 'Error de servidor',
  try_again: 'Intentar de Nuevo',
  my_agency: 'Mi agencia',
  manage_agency: 'Administrar agencia',
  reservations: 'Reservaciones',
  commissions: 'Comisiones',
  agents: 'Agentes',
  paid: 'Pagado',
  pending: 'Pendiente',
  beneficiary: 'Titular de la reservación',
  check_in: 'Check-in',
  check_out: 'Check-out',
  nights: 'Noche | Noches',
  plan: 'Plan',
  payment: 'Pago',
  reservation_details: 'Detalles de Reservación',
  search_by_name_or_email: 'Busca por No. de orden',
  order: 'Orden',
  summary: 'Resumen',
  total_to_pay: 'Total a pagar',
  pending_payment: 'Pago pendiente',
  confirmation_code: 'Confirmación',
  operation_date: 'Fecha de operación',
  description: 'Descripción',
  reservation_items: 'Artículos de reservación',
  item_number: 'Número de item',
  reserved: 'Reservada',
  cancelled: 'Cancelada',
  paid_status: 'Pagada',
  operated: 'Operada',
  requested: 'Solicitada',
  not_show: 'No Mostrada',
  audited: 'Auditada',
  modified: 'Modificada',
  annulled: 'Anulada',
  blocked: 'Bloqueada',
  booked: 'Reservada',
  my_reservations: 'Reservaciones',
  advanced_search: 'Búsqueda Avanzada',
  reservation_date: 'Fecha de reservación',
  product: 'Producto',
  reservation_status: 'Estado de reservación',
  confirmation_status: 'Estado de Confirmación',
  payment_status: 'Estado de Pago',
  payment_method: 'Método de Pago',
  not_paid: 'No Pagado',
  confirmed: 'Confirmado',
  clear_filters: 'Limpiar Filtros',
  apply_filters: 'Buscar',
  services: 'Servicios',
  order_number: 'Número de orden',
  confirmed_services: 'Servicios confirmados',
  booking: 'Reservar',
  amount: 'Importe',
  balance: 'Saldo',
  item: 'Artículo|Artículos',
  of: 'de',
  created_at: 'Creada el ',
  by: 'por',
  arrive: 'Entrada',
  leave: 'Salida',
  remember_me: 'Recordar cuenta',
  passengers: 'Pasajero | Pasajeros',
  estimate_duration: 'Duración estimada por viaje: {duration}',
  max_passengers: 'Hasta {count} pasajeros',
  max_suitcases: 'Hasta {count} medianas',
  door_to_door: 'Servicio puerta a puerta',
  transfer_cancel_policy: 'Cancelaciones o modificaciones 48 horas antes sin costo.',
  airline: 'Aerolínea',
  flight_number: 'Número de vuelo',
  arrival_time: 'Hora llegada',
  departure_time: 'Hora salida',
  notes: 'Notas',
  flight_info: 'Información de vuelo',
  arriving: 'Llegada',
  arrival: 'Llegada',
  departure: 'Salida',
  pick_up: 'Recogida',
  international_flight: 'Vuelo Internacional',
  first_trip: 'Primer viaje',
  second_trip: 'Segundo viaje',
  commission_pending: 'Comisión pendiente',
  commission_discount: 'Descuento de comisión',
  resend_invite: 'Reenviar invitación',
  resend_error: 'La invitación no se ha podido enviar, intenta de nuevo.',
  resend_success: 'La invitación se ha reenviado correctamente.',
  email_error: 'Correo electrónico inválido.',
  phone_error: 'Número de teléfono o celular inválido. Máximo 10 dígitos.',
  more_images: 'Fotos más',
  book_hotel: 'Reservar Hotel',
  see_details: 'Ver detalles',
  stay: 'Estancia',
  travel_complements: 'Complementos de viaje',
  find_more_experiences: 'Encuentra más experiencias por categoria',
  category: 'Categoría',
  average_price: 'Tarifa promedio por persona para el día',
  average_per_person: 'Promedio por persona',
  select_date: 'Seleccionar fecha',
  add: 'Agregar',
  quote: 'Cotizar',
  continue_reservation: 'Continuar reservación',
  check_reservation: 'Revisar Reservación',
  subtotal: 'Subtotal',
  select_origin: 'Selecciona  el aeropuerto de origen.',
  add_transfer: 'Agregar Traslado',
  active: 'Activo',
  inactive: 'Inactivo',
  status: 'Estatus',
  add_agent: 'Agregar Agente',
  mobile: 'Móvil',
  save: 'Guardar',
  update_agent: 'Editar Agente',
  agent_error: 'El agente no se ha podido guardar, verifica los campos.',
  cross_sell_text: '¡Haz que tu estadía sea inolvidable! Ofrecemos complementos exclusivos para que disfrutes al máximo tu viaje. Elige entre traslados cómodos y actividades emocionantes para agregar un toque especial a tu experiencia.<b> Estos complementos son opcionales </b> y están diseñados para brindarte mayor comodidad y diversión durante tu estancia.',
  forgot_password: '¿Olvidaste tu contraseña?',
  send: 'Enviar',
  forgot_instructions_1: 'Ingresa tu dirección de correo electrónico y te enviaremos un enlace para restablecer tu contraseña.',
  forgot_instructions_2: 'Si no recibes el correo, revisa tu carpeta de spam o intenta de nuevo.',
  password_email_sent: 'Hemos enviado un correo electrónico con instrucciones para restablecer tu contraseña.',
  my_commissions: 'Mis Comisiones',
  pending_payments: 'Pagos Pendientes',
  received_payments: 'Pagos Recibidos',
  payment_date: 'Fecha de Pago',
  total_pending_commissions: 'Total de comisiones pendientes',
  estimated_payment_date: 'Fecha estimada de pago',
  payment_amount: 'Monto de pago',
  reservations_number: 'Número de reservaciones',
  details: 'Detalles',
  included_reservations: 'Reservaciones incluidas',
  hotel: 'Hotel',
  experience: 'Experiencia',
  percentage: 'Porcentaje',
  commission_amount: 'Monto de comisión',
  total_commissions: 'Total de comisiones',
  total_paid_commissions: 'Total de comisiones pagadas',
  payment_reference: 'Referencia',
  the_reservation_is_in_euros: 'La reservación se muestra en euros (€); sin embargo, el cobro se realizará en dólares estadounidenses (USD) al tipo de cambio vigente en el momento de la transacción.',
  commission_discounts_not_included: 'Descuentos por comisiones no incluidos.',
  price_to_pay: 'Precio a pagar',
  resend_email: 'Para su conveniencia, podemos enviarle su itinerario de viaje por correo electrónico. Solo tiene que introducir su dirección de correo electrónico en el campo inferior y hacer clic en «Enviar».',
  email_sended: 'El itinerario de viaje se ha enviado correctamente.',
  email_send_failed: 'Parece que itinerario de viaje no se ha enviado. Por favor inténtalo de nuevo.'
}
